
.rotate {
  width: 1500px;
  animation: rotation 180s infinite linear;
  filter: blur(5px);
}


@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
