$primary:		  #ed8507;
$secondary:   #38d2d8;

$bg-dark:	#383131;
$bg-light:	#ffffff;
$highlight:	#ffe8e8;

$body-bg: $bg-light;
$body-color: $bg-dark;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "dark": $bg-dark,
  "light": $highlight
);
$enable-rounded: false;
$enable-gradients: true;

@import '~bootstrap/scss/bootstrap.scss';

h1 { font-size:2.6rem; }
h2 { font-size:2.0rem; }
h3 { font-size:1.4rem; }
h4 { font-size:1.2rem; }
h5 { font-size:1.0rem; }
h6 { font-size:0.8rem; }


